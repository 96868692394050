@import "../../../Styles/theme.scss";

// This is a mobile-first style-sheet.
// Find the desktop changes below, at min-width 700px;
.support_desktop_arrangement {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: $tablet-padding;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  color: $neutral7;
}

.card_top {
  width: 100%;
}

.cannot_complete_install_message {
  margin-top: 24px;
  color: $risk3;
  font-family: "Inter";
  font-size: $bodyMedium;
  font-weight: $regular;
}

.bottom_buttons {
  display: flex;
  flex: 2;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 48px;
}

.video_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral2;
  height: 400px;
  border-radius: 4px;
}

@media (max-width: 450px) {
  .bottom_buttons {
    display: flex;
    flex-direction: column;
    flex: 2;
    margin-top: 48px;
    justify-content: flex-start;
  }
}

// Desktop, 800px and higher
@media (min-width: 900px) {
  .page {
    padding: $mobile-padding;
  }
  .card {
    padding: $desktop-padding;
    justify-content: center;
    align-items: center;
  }
  .card_top {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    width: 360px + 355px + 32px; // left, right, padding
  }
  .desktop_left {
    display: flex;
    flex-direction: column;
    width: 355px;
    min-width: 355px;
    max-width: 355px;
    margin-right: 32px;
  }
  .desktop_right {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
  }
  .bottom_buttons {
    width: 360px + 355px + 32px; // left, right, padding
  }
}
