@import "../../Styles/theme.scss";

.content {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 22px 25px;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 4px;
  h2 {
    font-size: 23px;
    font-weight: $semiBold;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.section {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 16px;
  width: 100%;
}

.line {
  margin: 24px 0 16px 0;
}

.no_falls {
  background-color: $expected1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px;
  border-radius: 8px;
  box-sizing: border-box;
  h3 {
    font-size: $heading3;
    font-weight: $mediumWeight;
    text-transform: none;
    color: $neutral7;
    margin: 0;
  }
}

.footer {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

@media (max-width: $mobile_break_point) {
  .content {
    padding: 43px 22px 22px 22px;
    margin: 0;
  }
}
