@import "../../Styles/theme.scss";

.header {
  background-color: $white;
  margin-bottom: 8px;
  padding: 24px 40px;
  border-radius: 4px;
}

.heading_text {
  color: $neutral7;
  font-size: $heading2;
  font-weight: $mediumWeight;
  line-height: 48px;
  margin: 0;
  padding: 0;
  margin-top: 16px;
}

.heading_subtext {
  color: $neutral6;
  font-size: $bodyMedium;
  line-height: $extraWide;
  display: flex;
  align-items: center;
  gap: 4px;
}

.content {
  background-color: $white;
  border-radius: 4px;
  padding: 24px 40px;
}

.content_header {
  color: $neutral7;
  margin: 24px 0px 0px 0px;
  border-bottom: 1px solid $neutral2;
  padding-bottom: 24px;
}

.no_falls {
  padding-top: 40px;
  padding-bottom: 100px;
  text-align: center;

  .no_falls_text {
    color: $neutral7;

    font-size: $heading3;
    font-weight: $semiBold;
    line-height: $superWide;
    text-transform: none;
  }
}
