@import "../../Styles/theme.scss";

.buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
}

.shared_input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral3;
  border-radius: 8px;
  font-size: $bodySmall;
  background-color: $white;

  &:focus-visible {
    outline: none;
    border: 1px solid $primary3;
  }
}

.input {
  @extend .shared_input;
}

.text_area {
  @extend .shared_input;
  resize: none;
  width: 100%;
  height: 80px;
  line-height: 21px;
}

.label {
  font-weight: 600;
  color: $neutral7;
  line-height: 26px;
  font-size: $bodyMedium;
  margin: 0;
}

.field_check {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  span {
    vertical-align: middle;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.text_area_label {
  @extend .label;

  margin-bottom: 8px;
}

.error_message {
  margin-top: 8px;
  color: $risk6;
}
