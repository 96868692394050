@import "../../Styles/theme.scss";

.qr_loading_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.user_initials {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $neutral7;
  font-weight: $mediumWeight;
  background-color: $neutral2;
  min-width: 63px;
  min-height: 63px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  margin: 20px;
}

.logo {
  margin: 20px;
  object-fit: contain;
  align-self: center;
}

@media (max-width: $mobile-break-point) {
  .qr_loading_container {
    flex-direction: column;
  }
  .logo {
    width: 76px;
  }
}
