@import "../../../Styles/theme.scss";

.sensor_card {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  font-family: "Inter";
  border-radius: 8px;
}

.sensor_card_error {
  @extend .sensor_card;
  border: 1px solid $risk3;
}

.error_container {
  font-size: $bodySmall;
  padding-top: 12px;
  padding-bottom: 12px;
}

.error_message {
  color: $risk3;
}

.email {
  color: $secondary3;
  text-decoration-line: underline;
}

.sensor_card_default {
  @extend .sensor_card;
  border: 1px solid $neutral3;
}

.top_sensor_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 22.42px;
  font-weight: $mediumWeight;
  line-height: 26.73px;
  text-align: left;
  height: 25px;
}

.location_instructions {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  height: 60px;
  span {
    font-weight: $regular;
    line-height: 22px;
  }
}

.sensor_card_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.sensor_connection_error {
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 22px;
  text-align: left;
  color: $risk6;
}

.video_container {
  display: flex;
  justify-content: center;
}
.video {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: 100%; //
  max-width: 500px;
  border-radius: 8px;
}

@media (max-width: 900px) {
  .location_instructions {
    height: unset;
  }
}