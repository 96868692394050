@import "../../../Styles/theme.scss";

.header {
  color: $neutral7;

  /* Heading 02 */
  font-size: $heading3;
  font-style: normal;
  font-weight: $semiBold;
  line-height: $superWide; /* 138.462% */
  margin-top: 30px;
  margin-bottom: 8px;
}

.subtext {
  color: $neutral6;

  /* Paragraph 01 */
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: $extraWide; /* 162.5% */
  margin-bottom: 24px;
}

.label {
  color: $neutral7;
  font-size: $bodyMedium;
  font-weight: $semiBold;
  margin: 0;
}

.information {
  color: $neutral6;
  font-size: $bodyMedium;
  line-height: $extraWide;
  font-weight: $regular;
}

.heading_section {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.information_container {
  display: flex;
  gap: 8px;
}

.information_container svg {
  flex-shrink: 0; // Prevents the icon from shrinking
  width: 24px; // Ensure a consistent size for the icon
  height: 24px;
}

.divider {
  margin: 24px 0px;
}
