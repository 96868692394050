@import "../../../Styles/theme.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 16px;
  border-radius: 12px;
  background-color: $neutral0;
  box-sizing: border-box;
  font-family: Inter;
  border: 2px solid $neutral2;
  h3 {
    font-size: $heading5;
    font-weight: $semiBold;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  h3 {
    margin: 0;
    text-transform: none;
  }
  margin-bottom: 12px;
  // remove the margin-bottom for the last row
  &:last-child {
    margin-bottom: 0;
  }
}
