@import "../../../Styles/theme.scss";

.button {
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;

  &:disabled {
    pointer-events: none;
  }
}

.left_icon,
.right_icon {
  display: flex;
  align-items: center;
}

.primary {
  color: $neutral7;
  background-color: $primary3;
  border: 1px solid $primary3;

  &:disabled {
    color: $neutral5;
    background-color: $neutral2;
    border: 1px solid $neutral2;
  }

  &:active:not(:disabled) {
    color: $neutral5;
    background-color: $neutral2;
    border: 1px solid $neutral2;
  }
}

.secondary {
  color: $neutral7;
  background-color: $white;
  border: 1px solid $primary3;

  &:disabled {
    color: $neutral5;
    background-color: $white;
    border: 1px solid $neutral3;
  }

  &:active:not(:disabled) {
    color: $neutral7;
    background-color: $primary3;
    border: 1px solid $primary3;
  }
}

.danger {
  color: $neutral7;
  background-color: $risk3;
  border: 1px solid $risk3;
  // handle hover state
  &:hover {
    color: $neutral0;
    background-color: $risk4;
    border: 1px solid $risk4;
  }
  // handle press state
  &:active {
    color: $neutral0;
    background-color: $risk5;
    border: 1px solid $risk5;
  }
}

.danger_secondary {
  color: $risk6;
  background-color: $neutral0;
  border: 1px solid $risk3;
  // handle hover state
  &:hover {
    background-color: $risk1;
  }
  // handle press state
  &:active {
    background-color: $risk2;
  }
}

@media (hover: hover) {
  .primary:hover:not(:disabled) {
    color: $neutral7;
    background-color: $primary4;
    border: 1px solid $primary4;
  }

  .secondary:hover:not(:disabled) {
    color: $neutral7;
    background-color: $primary1;
    border: 1px solid $primary3;
  }
}
