@import "../../Styles/theme.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.spacer {
  height: 20px;
}
