@import "../../../Styles/theme.scss";

.page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card {
  display: flex;
  box-sizing: border-box;
  position: relative;
  color: $neutral7;
}

.card_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.instructions {
  margin-top: 24px;
  h2 {
    margin-top: 0;
  }
}

.main_title {
  font-size: 32px;
}
.secondary_title {
  font-size: 22px;
}

.install_card {
  border: 1px solid $neutral3;
  border-radius: 8px;
  padding: 24px;
  margin-top: 16px;

  .header {
    font-size: 16px;
    font-weight: 400;
    color: $neutral5;
  }

  .text {
    color: $neutral7;
    font-size: 22px;
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  div {
    flex: 1;
  }
}

.text_area {
  height: 180px;
  resize: none;
  border: 1px solid $neutral3;
  border-radius: 8px;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  padding: 16px;
  &:focus-visible {
    outline: 1px solid $primary3;
    border-color: transparent;
  }
}

@media (max-width: $mobile-break-point) {
  .content {
    padding: $mobile-padding;
  }
  .card {
    padding: $mobile-padding;
    flex-direction: column;
  }

  .top {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .card_right {
    width: 100%;
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .instructions {
    text-align: left;
  }
}
