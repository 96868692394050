@import "../../Styles/theme.scss";
.dashboard_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.dashboard_header_container_left {
  display: flex;
}

.dashboard_header_container_right {
  display: flex;
  align-items: center;
}
// Add a margin to the left of the last child
.dashboard_header_container_right > *:last-child {
  margin-left: 16px;
}

.page_title {
  font-size: $heading2;
  font-weight: $mediumWeight;
  margin-bottom: 0;
}

.user_info {
  color: $neutral5;
  font-size: $headerBody;
}

.error {
  color: $risk4;
  font-size: $heading3;
  text-transform: none;
}

@media (max-width: $mobile-break-point) {
  .dashboard_header_container {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
  .page_title {
    margin-bottom: 16px;
  }
}
