@import "../../Styles/theme.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.spacer {
  height: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}

@media (max-width: $mobile-break-point) {
  .buttons {
    justify-content: center;
  }
}
