@import "../../../Styles/theme.scss";

.instructions_steps {
  display: flex;
  flex-direction: column;
  li {
    font-family: "Inter";
    font-size: $bodyMedium;
    font-weight: $regular;
    line-height: 22px;
    text-align: left;
    padding-bottom: 16px;
    color: $neutral6;
  }
  p {
    font-size: $bodyMedium;
    line-height: 22px;
    color: $neutral6;
  }
}

.note {
  font-weight: $mediumWeight;
  span {
    font-weight: $regular;
    color: $neutral6;
  }
  margin-bottom: 16px;
}

.description {
  color: $neutral6;
  margin-top: 32px;
  margin-bottom: 32px;
}
