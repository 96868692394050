@import "../../../Styles/theme.scss";

.desktop_image_container {
  margin-top: "12px";
  margin-bottom: "16px";
}

.desktop_instructions {
  display: flex;
  flex-direction: column;
  ol {
    li {
      font-family: "Inter";
      font-size: $bodyMedium;
      font-weight: $regular;
      line-height: 22px;
      text-align: left;
      padding-bottom: 16px;
      color: $neutral6;
    }
  }
}

.note {
  font-weight: $mediumWeight;
  span {
    font-weight: $regular;
    color: $neutral6;
  }
  margin-bottom: 16px;
}

.description {
  color: $neutral6;
  margin-top: 32px;
  margin-bottom: 32px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
