@import "../../Styles/theme.scss";

.location_placeholder {
  height: 85px;
}

.input {
  font-family: Inter;
  width: 100%;
  box-sizing: border-box;
  color: $neutral7;
  padding: 10px;
  border: 1px solid $neutral3;
  margin-top: 8px;
}

.input:focus-visible {
  outline: 1px solid $primary3;
  border-radius: 8px;
  border-color: $primary3;
}

.select_appliacnce {
  margin-top: 14px;
  label {
    color: $neutral6;
  }
}

.appliance_other {
  margin-top: 20px;
  margin-bottom: 16px;
  label {
    color: $neutral6;
  }
}

.error {
  color: $risk3;
  font-size: $bodySmall;
  padding-top: 12px;
  padding-bottom: 12px;
}
