@import "../../../Styles/theme.scss";

.content {
  padding: 40px 0px;
  border-bottom: 1px solid $neutral2;
}

.fall_log_content {
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
  gap: 24px;
}

.fall_log_content_box {
  flex: 1;
}

// flexbox dynamic line separator: https://ishadeed.com/article/flexbox-separator/
.fall_log_content:before {
  content: "";
  border: 1px solid $neutral2;
  align-self: stretch;
}

.fall_log_content_box_start {
  order: -1;
}

.fall_span {
  color: $neutral7;

  font-size: $subHeading;
  font-weight: $mediumWeight;
  line-height: 29px;
}

.fall_time_span {
  color: $neutral6;

  font-size: $subHeading;
  font-weight: $mediumWeight;
  line-height: 29px;
}

.information_container {
  display: flex;
  gap: 8px;
}

.information_container svg {
  flex-shrink: 0; // Prevents the icon from shrinking
  width: 24px; // Ensure a consistent size for the icon
  height: 24px;
}

.label {
  color: $neutral7;
  font-size: $bodyMedium;
  font-weight: $semiBold;
  line-height: $extraWide;
  text-wrap: wrap;
  word-break: break-word;
  max-width: 100%;
  margin-bottom: 8px;
}

.information {
  color: $neutral6;
  font-size: $bodyMedium;
  line-height: $extraWide;
  font-weight: $regular;
}

@media (max-width: $mobile-break-point) {
  .fall_log_content {
    flex-direction: column;
  }
}
